<template>
  <svg
    aria-hidden="true"
    width="1em"
    height="1em"
    class="inline"
    :class="{ spinner: props.spin, 'cursor-pointer': !!content }"
    v-tooltip="tooltipProps"
    @mouseover.once="trackIconEvent"
    @click="
      appService.pushToDataLayer(
        trackingEventname || null,
        null,
        trackingType || null,
      )
    "
    :data-t-c="legacyTracking?.category || null"
    :data-t-a="legacyTracking?.action || null"
    :data-t-l="legacyTracking?.label || null"
  >
    <use :href="symbolId" />
  </svg>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { trackEvent } from '@/services/gtm.service'
import LegacyTrackingConfig from '@/types/LegacyTrackingConfig'
import appService from '@/services/app.service'

const props = defineProps<{
  prefix?: string
  name: string
  spin?: boolean
  content?: string // tooltip content
  tooltipInitialDuration?: number | string
  trackingEventname?: string
  trackingType?: string
  legacyTracking?: LegacyTrackingConfig
}>()

const tooltipVisible = ref(false)

const tooltipProps = computed(() => {
  return {
    content: props.content,
    isDisabled: !props.content,
    distance: 10,
    triggers: ['click', 'hover'],
    placement: 'bottom-start',
    shown: tooltipVisible.value,
    autoHide: !tooltipVisible.value,
  }
})

onMounted(() => {
  if (props.content && props.tooltipInitialDuration) {
    tooltipVisible.value = true
    setTimeout(
      () => {
        tooltipVisible.value = false
      },
      (props.tooltipInitialDuration as number) * 1000,
    )
  }
})

const trackIconEvent = () => {
  if (props.content) {
    trackEvent('TooltipHover', `tooltip: ${props.content}`)
  }
}

const symbolId = computed(() => `#${props.prefix ?? 'icon'}-${props.name}`)
</script>

<style scoped>
.spinner {
  animation: spin 1.5s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
